/* eslint-disable no-nested-ternary */
import {
  Box, Button, Divider, Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayerTeam, addPlayerToTeam, changePlayerType } from 'redux/slices';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';

import { RemovePlayer } from './RemovePlayer';
import { PrioritizePlayer } from './PrioritizePlayer';

export default function WaitingPlayerCard({ player, isGuestList = false }) {
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.players);
  const playersPerTeam = useSelector((state) => state.game.playersPerTeam);
  const { playerType } = player;

  const dispatchPlayerType = (type) => {
    dispatch(changePlayerType({ playerId: player.playerId, playerType: type }));
  };

  const handleClick = () => {
    console.log('playerType', playerType);
    const isAttacker = playerType === 'Attacker';
    const isSetter = playerType === 'Setter';
    const isFlex = playerType === 'Flex';
    const isBoth = playerType === 'Both';
    if (isSetter) { return dispatchPlayerType('Attacker'); }
    if (isAttacker) { return dispatchPlayerType('Flex'); }
    if (isFlex) { return dispatchPlayerType('Both'); }
    if (isBoth) { return dispatchPlayerType('Setter'); }
    return dispatchPlayerType('Setter');
  };

  const isSetter = player.playerType === 'Setter';
  const isAttacker = player.playerType === 'Attacker';
  const isBoth = player.playerType === 'Both';

  const handleAddPlayer = () => {
    const isTeamAFull = teams.filter((p) => p.teamId === 1).length === playersPerTeam;
    const isTeamBFull = teams.filter((p) => p.teamId === 2).length === playersPerTeam;
    const teamId = isTeamAFull ? 2 : 1;

    if (!isTeamAFull || !isTeamBFull) {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId }));
      return dispatch(addPlayerToTeam({ playerId: player.playerId, teamId }));
    } return alert('Não há times disponíveis');
  };

  return (
    <Box sx={{
      width: { xs: '100%', md: '80%' },
    }}
    >
      <Box
        key={`${player.playerId}-${player.playerName}`}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          borderRadius: '5px',
          padding: '10px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Typography onClick={handleClick} variant="h6">
            {player.playerName}
            (
            {player.waitingFor === 10 ? 'P' : player.waitingFor}
            )
          </Typography>
          {isAttacker && <SportsHandballIcon onClick={handleClick} sx={{ marginLeft: '-15px', color: 'blue', height: '30px' }} /> }
          {isSetter && <SportsVolleyballIcon onClick={handleClick} sx={{ marginLeft: '-15px', color: 'red', height: '30px' }} /> }
          {isBoth && (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <SportsHandballIcon onClick={handleClick} sx={{ marginLeft: '-15px', color: 'blue', height: '30px' }} />
            <SportsVolleyballIcon onClick={handleClick} sx={{ marginLeft: '-15px', color: 'red', height: '30px' }} />
          </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          {!isGuestList && (
          <Button
            variant="contained"
            onClick={handleAddPlayer}
          >
            Jogar
          </Button>
          )}
          <RemovePlayer playerId={player.playerId} isGuestList={isGuestList} />
          <PrioritizePlayer disabled={player.waitingFor === 10} playerId={player.playerId} />

        </Box>
      </Box>
      <Divider variant="fullWidth" />
    </Box>
  );
}
