import React from 'react';
import { useDispatch } from 'react-redux';
import { addPriority } from 'redux/slices';
import { ConfirmationModal } from './ConfirmationModal';

export function PrioritizePlayer({ playerId, isPlaying = false, disabled }) {
  const dispatch = useDispatch();

  const handlePrioritizePlayer = () => {
    dispatch(addPriority(playerId));
    if (isPlaying !== false) isPlaying();
  };

  return (
    <ConfirmationModal
      title="Priorizar jogador"
      description="Tem certeza que deseja priorizar este jogador?"
      handleConfirm={handlePrioritizePlayer}
      handleCancel={() => { }}
      triggerLabel="P"
      disabled={disabled}
    />
  );
}
